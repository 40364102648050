import * as actions from '../Actions';
import { accessoriesDB, memoriesDB } from './db';

const initialState = {
  jobs: [],
  deviceTypes: [],
  brands: [],
  devices: [],
  items: [],
  customers: [],
  technicians: [],
  memories: memoriesDB,
  accessories: accessoriesDB,

  currentJob: {
    returnDate: null,
    jobServices: [],
    extraItems: [],
    totals: { subtotal: 0, total: 0, discount: 0 },
    technician: '',
    customer: {
      type: 'private',
      name: '',
      companyRepresentative: '',
      street: '',
      houseNo: '',
      postalCode: '',
      city: '',
      phone: '',
      signatures: null,
      differentNameOnInvoice: false,
      invoiceOf: {
        name: '',
        street: '',
        houseNo: '',
        postalCode: '',
        city: '',
        phone: '',
      },
    },
    device: {
      id: null,
      name: '',
      type: '',
      repairServices: [],
      serial: '',
      notes: '',
      defects: [],
      memory: '',
      accessories: [],
      password: '',
      photos: [],
    },
  },

  currDeviceType: {},
  currentDevices: [],

  backdropVisible: false,
  backdropComponent: '',
  selectedJob: {},

  currentAction: '',

  deviceToEdit: {},

  databaseLoading: false,
  deviceTypesLoading: false,
  brandsLoading: false,
  devicesLoading: false,
  itemsLoading: false,
  customersLoading: false,
  jobsLoading: false,
  techniciansLoading: false,

  modelSearchInput: '',
  jobsSearchActive: false,
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.setBackdropVisible().type:
      return {
        ...state,
        backdropVisible: action.visible,
        backdropComponent: action.component,
        currentAction: action.currentAction,

        currentJob: action.visible ? state.currentJob : initialState.currentJob,
        currDeviceType: action.visible ? state.currDeviceType : {},
        currentDevices: action.visible ? state.currentDevices : [],
      };
    case actions.addJobCompleted().type:
      return {
        ...state,
        currentJob: initialState.currentJob,
        currDeviceType: initialState.currDeviceType,
        currentDevices: initialState.currentDevices,
      };
    case actions.setSelectedJob().type:
      return {
        ...state,
        selectedJob: action.job,
        currentJob: action.job,
      };
    case actions.setDeviceType().type:
      // console.log('action.dType', action.dType)
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          device: { ...state.currentJob.device, type: action.dType.name },
        },
        currentDevices: state.devices.filter((d) => d.deviceTypeId === action.dType.id),
        currDeviceType: action.dType,
      };

    case actions.setDeviceModel().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          device: {
            ...state.currentJob.device,
            id: action.dModel.id,
            name: action.dModel.fullName,
            repairServices: [
              ...state.currentDevices.filter((d) => d.id === action.dModel.id)[0].repairServices,
            ],
          },
        },
      };

    case actions.setRepairServices().type:
      return {
        ...state,
        currentJob: { ...state.currentJob, jobServices: action.rServices },
      };

    case actions.setExtraItems().type:
      return {
        ...state,
        currentJob: { ...state.currentJob, extraItems: action.eItems },
      };

    case actions.setDiscount().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          totals: { ...state.currentJob.totals, discount: action.discount },
        },
      };

    case actions.setTotals().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          totals: {
            ...state.currentJob.totals,
            subtotal: action.subtotal,
            total: action.total,
            insurance: action.insurance,
          },
        },
      };

    case actions.setDeviceAttributes().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          device: {
            ...state.currentJob.device,
            [action.attribute]: action.value,
          },
        },
      };

    case actions.setCustomerDetails().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          customer: {
            ...state.currentJob.customer,
            [action.attribute]: action.value,
          },
        },
      };

    case actions.setReturnDate().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          returnDate: action.date,
        },
      };

    case actions.setDifferentNameInputs().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          customer: {
            ...state.currentJob.customer,
            invoiceOf: { ...state.currentJob.customer.invoiceOf, [action.attribute]: action.value },
          },
        },
      };

    case actions.setCurrentJob().type:
      return {
        ...state,
        currentJob: action.job === null ? initialState.currentJob : action.job,
      };
    case actions.setJobNumber().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          jobNumber: action.num,
        },
      };

    case actions.addJob().type:
      return {
        ...state,
        currentJob: initialState.currentJob,
        backdropVisible: false,
        backdropComponent: '',
        currentAction: '',
        currDeviceType: {},
        currentDevices: [],
      };

    case actions.setEditMode().type:
      const devType = state.deviceTypes.filter((d) => d.name === state.currentJob.device.type)[0];
      return {
        ...state,
        currDeviceType: devType,
        currentDevices: state.devices.filter((d) => d.deviceTypeId === devType.id),
        backdropComponent: 'job wizard',
        currentAction: 'editing',
      };

    case actions.updateJob().type:
      return {
        ...state,
        currentJob: initialState.currentJob,
        backdropVisible: false,
        backdropComponent: '',
        currentAction: '',
        currDeviceType: {},
        currentDevices: [],
      };

    case actions.setJobs().type:
      return {
        ...state,
        jobs: action.jobs,
      };

    case actions.startGettingDeviceTypes().type:
      return {
        ...state,
        deviceTypesLoading: true,
      };

    case actions.setDeviceTypes().type:
      return {
        ...state,
        deviceTypes: action.dTypes,
        deviceTypesLoading: false,
      };

    case actions.startGettingBrands().type:
      return {
        ...state,
        brandsLoading: true,
      };

    case actions.setBrands().type:
      return {
        ...state,
        brands: action.brands,
        brandsLoading: false,
      };

    case actions.startGettingDevices().type:
      return {
        ...state,
        devicesLoading: true,
      };

    case actions.setDevices().type:
      return {
        ...state,
        devices: action.devices,
        devicesLoading: false,
      };

    case actions.startGettingItems().type:
      return {
        ...state,
        itemsLoading: true,
      };

    case actions.setItems().type:
      return {
        ...state,
        items: action.items,
        itemsLoading: false,
      };

    case actions.startGettingTechnicians().type:
      return {
        ...state,
        techniciansLoading: true,
      };

    case actions.setTechnicians().type:
      return {
        ...state,
        technicians: action.technicians,
        techniciansLoading: false,
        databaseLoading: false,
      };

    case actions.startLoadDatabase().type:
      return {
        ...state,
        databaseLoading: true,
      };
    case actions.databaseLoadComplete().type:
      return {
        ...state,
        databaseLoading: false,
      };

    case actions.setDeviceToEdit().type:
      return {
        ...state,
        deviceToEdit: {
          id: action.device.id,
          model: action.device.model,
          deviceType: state.deviceTypes.filter((d) => d.id === action.device.deviceTypeId)[0],
          brand: state.brands.filter((b) => b.id === action.device.brandId)[0],
          repairServices: action.device.repairServices,
          created: action.device.created,
        },
      };

    case actions.setModelSearchInput().type:
      return {
        ...state,
        modelSearchInput: action.value,
      };

    case actions.startGettingCustomers().type:
      return {
        ...state,
        customersLoading: true,
      };

    case actions.setCustomers().type:
      return {
        ...state,
        customers: action.customers,
        customersLoading: false,
      };

    case actions.setSingleDevice().type:
      return {
        ...state,
        devices: state.devices.map((d) => {
          if (d.id === action.id) {
            return { ...d, repairServices: [...d.repairServices, action.service] };
          } else {
            return d;
          }
        }),
        currentDevices: state.currentDevices.map((d) => {
          if (d.id === action.id) {
            return { ...d, repairServices: [...d.repairServices, action.service] };
          } else {
            return d;
          }
        }),
        currentJob: {
          ...state.currentJob,
          device: {
            ...state.currentJob.device,
            repairServices: [...state.currentJob.device.repairServices, action.service],
          },
        },
        devicesLoading: false,
      };

    case actions.setJobsSearchActive().type:
      return {
        ...state,
        jobsSearchActive: action.toggle,
      };

    case actions.updateJobStatusStart().type:
      return {
        ...state,
        jobsLoading: true,
      };

    case actions.updateCurrentJobStatus().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          status: action.status,
        },
        jobsLoading: false,
      };
    case actions.updateCurrentJobTechnician().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          technician: action.technician,
        },
        jobsLoading: false,
      };
    case actions.setAutoCustomerDetails().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          customer: action.details
            ? action.details.signatures
              ? action.details
              : { ...state.currentJob.customer, ...action.details }
            : initialState.currentJob.customer,
        },
        jobsLoading: false,
      };
    case actions.setCustomerSignatures().type:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          customer: {
            ...state.currentJob.customer,
            signatures: action.data ? action.data : null,
          },
        },
        jobsLoading: false,
      };

    default:
      return state;
  }
};

export default jobReducer;
