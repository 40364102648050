import * as React from "react";
import DeviceDetailsAccordion from "../Utils/DeviceDetailsAccordion";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDeviceAttributes } from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";

function DeviceDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currDeviceType = useSelector((state) => state.job.currDeviceType);
  const currDevice = useSelector((state) => state.job.currentJob.device);
  return (
    <div>
      <div style={{ marginTop: "20px", textAlign: "left" }}>
        <DeviceDetailsAccordion
          functionChecks={currDeviceType.functionChecks}
          currDevice={currDevice}
        />
        <div style={{ marginTop: "20px", display: "flex" }}>
          <div style={{ width: "50%", marginRight: "30px" }}>
            <TextField
              id="outlined-basic"
              value={currDevice.serial}
              onChange={(e) => dispatch(setDeviceAttributes("serial", e.target.value))}
              variant="outlined"
              placeholder={t("IMEI/Serial No.")}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "1.2rem",
                },
              }}
            />
          </div>
          <div style={{ width: "50%", marginBottom: "30px" }}>
            <TextField
              id="outlined-basic"
              label={t("Notes / Description")}
              variant="outlined"
              inputProps={{ maxLength: 200 }}
              placeholder={t("Max. 200 characters")}
              value={currDevice.notes}
              onChange={(e) => dispatch(setDeviceAttributes("notes", e.target.value))}
              sx={{
                "& .MuiOutlinedInput-input": { fontSize: "1.2rem" },
              }}
              fullWidth
              multiline
              rows={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceDetails;
