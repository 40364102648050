import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export default function Cards() {
  const { t } = useTranslation();
  const jobs = useSelector((state) => state.job.jobs);
  const [total, setTotal] = React.useState(0);
  const [pending, setPending] = React.useState(0);
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    const thisMonth = new Date().getMonth();
    const totalJobsThisMonth = jobs.filter(
      (j) => new Date(j.timestamp.seconds * 1000).getMonth() === thisMonth
    );
    const pendingJobsThisMonth = totalJobsThisMonth.filter((j) => j.status === "pending");
    const completedJobsThisMonth = totalJobsThisMonth.filter((j) => j.status === "completed");
    setTotal(totalJobsThisMonth.length);
    setPending(pendingJobsThisMonth.length);
    setCompleted(completedJobsThisMonth.length);
    return () => {};
  }, [jobs]);

  return (
    <>
      <motion.div initial={{ y: -70 }} animate={{ y: 0 }} transition={{ duration: 0.2 }}>
        <Card sx={{ minWidth: 350, padding: "16px", mr: "30px" }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("This Month")}
            </Typography>
            <Typography variant="h1" component="div" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
              {total}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {t("Total Reapair Jobs")}
            </Typography>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div initial={{ y: -60 }} animate={{ y: 0 }} transition={{ duration: 0.3 }}>
        <Card sx={{ minWidth: 350, padding: "16px", mr: "30px" }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("This Month")}
            </Typography>
            <Typography variant="h1" component="div" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
              {completed}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {t("Total Completed Jobs")}
            </Typography>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div initial={{ y: -50 }} animate={{ y: 0 }} transition={{ duration: 0.4 }}>
        <Card sx={{ minWidth: 350, padding: "16px", mr: "30px" }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("This Month")}
            </Typography>
            <Typography variant="h1" component="div" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
              {pending}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {t("Total Pending Jobs")}
            </Typography>
          </CardContent>
        </Card>
      </motion.div>
    </>
  );
}
