import React, { useEffect } from 'react';
import CompanyProfile from '../../components/Profile/CompanyProfile';

function Company({ setAdminAuthenticated }) {
  return (
    <div>
      <CompanyProfile setAdminAuthenticated={setAdminAuthenticated} />
    </div>
  );
}

export default Company;
