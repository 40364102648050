import * as actions from "../Actions";

const initialState = {
  settingsId: "",
  companyDetails: {
    name: "",
    street: "",
    houseNo: "",
    postalCode: "",
    city: "",
    phone: "",
    logoUrl: "",
    email: "",
    website: "",
    bank: "",
    accountNo: "",
    bankCode: "",
    iban: "",
    bic: "",
    regNo: "",
    uid: "",
    ceo: "",
    taxTitle: "",
    taxPercent: 0,
  },
  generalInvoice: {
    heading: "",
    introText: "",
    termsConditions: "",
  },
  estimationInvoice: {
    heading: "",
    introText: "",
    termsConditions: "",
  },
  lastUpdated: null,
  settingsLoading: false,
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.setCompanyDetails().type:
      return {
        ...state,
        companyDetails: {
          ...state.companyDetails,
          [action.attribute]: action.value,
        },
      };

    case actions.setGeneralInvoice().type:
      return {
        ...state,
        generalInvoice: {
          ...state.generalInvoice,
          [action.attribute]: action.value,
        },
      };
    case actions.setEstimationInvoice().type:
      return {
        ...state,
        estimationInvoice: {
          ...state.estimationInvoice,
          [action.attribute]: action.value,
        },
      };
      
    case actions.startSaving().type:
      return {
        ...state,
        settingsLoading: true,
      };

    case actions.setSettings().type:
      return {
        ...state,
        settingsId: action.id,
        companyDetails: action.settings.companyDetails,
        generalInvoice: action.settings.generalInvoice,
        estimationInvoice: action.settings.estimationInvoice,
        lastUpdated: action.settings.updated,
        settingsLoading: false,
      };

    default:
      return state;
  }
};

export default companyReducer;
