import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { fireStore } from '../../firebase/config';
import { collection, getDoc, getDocs, query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

export default function AdminPinDialog({ setAdminAuthenticated, adminAuthenticated }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [pin, setPin] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
  };

  const handleInputChange = (e) => {
    // only allow numbers
    setError(false);
    if (isNaN(e.target.value)) {
      return;
    }
    // if the length is greater than 5, then don't change
    if (e.target.value.length > 5) {
      return;
    }
    setPin(e.target.value);
  };

  const handleValidatePin = () => {
    setLoading(true);
    // convert pin to number
    const numPin = Number(pin);
    // get doc from firebase from admins collection where pin = pin
    const q = query(
      collection(fireStore, process.env.REACT_APP_SUBCOLLECTION_PATH + 'admins'),
      where('pin', '==', numPin)
    );
    getDocs(q).then((docSnap) => {
      if (docSnap.docs.length > 0) {
        setAdminAuthenticated(true);
        setError(false);
        setLoading(false);
        setOpen(false);
      } else {
        setLoading(false);
        setError(true);
        setAdminAuthenticated(false);
      }
    });
  };

  const handleCancel = () => {
    // setAdminAuthenticated(false);
    setOpen(false);
    // go back to dashboard
    navigate('/');
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}
        >
          <div>Admin PIN</div>
          <Lock color='error' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Please enter your 5 digit admin PIN to continue.')}
          </DialogContentText>
          <br />
          <TextField
            autoFocus
            margin="dense"
            value={pin}
            sx={{ fontSize: '4rem' }}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleValidatePin();
              }
            }}
            id="AdminPin"
            error={error}
            helperText={error ? t('Invalid PIN !') : ''}
            label="Admin PIN"
            type="password"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{t('Cancel')}</Button>
          {loading ? (
            <CircularProgress size={24} sx={{ mx: 2 }} />
          ) : (
            <Button
              variant="contained"
              onClick={handleValidatePin}
              autoFocus
              disabled={pin.length !== 5 || error}
            >
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
