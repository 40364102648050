import React, { useState } from 'react';

import DatePicker from '../../DatePicker/DatePicker';

import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

function JobsRange({ showRangeSelector, setShowRangeSelector, dateRange, setDateRange }) {
  const [dates, setDates] = useState([
    {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      key: 'selection',
    },
  ]);

  const { t } = useTranslation();
  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  // const generateOrders = () => {
  //   props.setLoading(true);
  //   const data = {
  //     startDate: format(dates[0].startDate, "yyyy-MM-dd HH:mm:ss"),
  //     endDate: format(dates[0].endDate, "yyyy-MM-dd HH:mm:ss"),
  //     // startDate: dates[0].startDate,
  //     // endDate: dates[0].endDate,
  //   };

  //   Axios.post(`${process.env.REACT_APP_API_URL}/order/filtered_orders`, data, {
  //     headers: { Authorization: `Bearer ${adminToken}` },
  //   })
  //     .then((res) => {
  //       console.log("res", res);
  //       props.setOrders(res.data);
  //       props.setOrderFilter(true);
  //       props.setShowFilter(false);
  //       props.setFilteredDates({
  //         startDate: format(dates[0].startDate, "dd-MM-yyyy"),
  //         endDate: format(dates[0].endDate, "dd-MM-yyyy"),
  //       });
  //       props.setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("ERROR:", err.message);
  //     });
  // };

  const handleRangeChange = () => {
    setDateRange({
      startDate: dates[0].startDate,
      endDate: dates[0].endDate,
    });
    setShowRangeSelector(false);
  };

  return (
    <Dialog
      open={showRangeSelector}
      onClose={() => setShowRangeSelector(false)}
      aria-labelledby="date-picker-dialog-title"
      maxWidth="md"
    >
      <DialogTitle
        id="date-picker-dialog-title"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant="h6" component="div">
          {t('Select Date Range')}
        </Typography>
        <IconButton onClick={() => setShowRangeSelector(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 4, pt: 0 }} style={{ minHeight: '60vh' }}>
        <DatePicker dates={dates} setDates={setDates} />
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button variant="contained" color="primary" fullWidth onClick={handleRangeChange}>
          {t('Show jobs from')}&nbsp; <b>{format(dates[0].startDate, 'dd-MM-yyyy')}</b>&nbsp;
          {t('to')}&nbsp;
          <b>{format(dates[0].endDate, 'dd-MM-yyyy')}</b>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default JobsRange;
