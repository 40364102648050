import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { searchCustomersByFilter } from '../../../Redux/Actions/fireactions';

function SearchFilter({ showSearchFilter, setShowSearchFilter }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState({
    name: '',
    phone: '',
    street: '',
    city: '',
    postalCode: '',
  });

  const handleClear = () => {
    setSearch({
      name: '',
      phone: '',
      street: '',
      city: '',
      postalCode: '',
    });
  };

  const handleClose = () => {
    handleClear();
    setShowSearchFilter(false);
  };

  const handleSearch = () => {
    console.log('search', search);
    dispatch(searchCustomersByFilter(search));
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={showSearchFilter}>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {t('Search Customers')}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          {t('Search for customers by name, phone, street, postal code or city.')}
        </Typography>
        <TextField
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={search.name}
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
        />
        <TextField
          margin="dense"
          id="phone"
          label="Phone"
          fullWidth
          value={search.phone}
          onChange={(e) => setSearch({ ...search, phone: e.target.value })}
        />
        <TextField
          margin="dense"
          id="street"
          label="Street"
          fullWidth
          value={search.street}
          onChange={(e) => setSearch({ ...search, street: e.target.value })}
        />
        <TextField
          margin="dense"
          id="postalCode"
          label="Postal Code"
          fullWidth
          value={search.postalCode}
          onChange={(e) => setSearch({ ...search, postalCode: e.target.value })}
        />
        <TextField
          margin="dense"
          id="city"
          label="City"
          fullWidth
          value={search.city}
          onChange={(e) => setSearch({ ...search, city: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClear}>{t('Clear')}</Button>
        <Button
          onClick={handleSearch}
          variant="contained"
          disabled={
            search.name === '' &&
            search.street === '' &&
            search.city === '' &&
            search.postalCode === '' &&
            search.phone === ''
          }
        >
          {t('Search')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SearchFilter;
