import * as React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import SideDrawer from '../../components/SideDrawer/SideDrawer';
import TopBar from '../../components/TopBar/TopBar';
import Dashboard from '../Dashboard/Dashboard';
import Jobs from '../Jobs/Jobs';
import Customers from '../Admin/Customers/Customers';
import Invoices from '../Invoices/Invoices';
import Company from '../Settings/Company';
import Labels from '../Settings/Labels';
import Backdrop from '../../components/Common/Backdrop';
import Invoice from '../Settings/Invoice';

import DeviceTypes from '../Admin/DeviceTypes/DeviceTypes';
import Brands from '../Admin/Brands/Brands';
import DevicesList from '../Admin/Devices/Devices';
import Items from '../Admin/Items/Items';
import Technicians from '../Admin/Technicians/Technicians';

import { motion } from 'framer-motion';
import AdminPinDialog from './AdminPinDialog';
import CheckPath from './CheckPath';

function Home() {
  const [open, setOpen] = React.useState(true);
  const [adminAuthenticated, setAdminAuthenticated] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const backdropVisible = useSelector((state) => state.job.backdropVisible);

  return (
    <BrowserRouter>
      <CheckPath
        setAdminAuthenticated={setAdminAuthenticated}
        adminAuthenticated={adminAuthenticated}
      />
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopBar open={open} toggleDrawer={toggleDrawer} />
        <SideDrawer open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xxl" sx={{ mt: 3, mb: 4 }} style={{}}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/jobs"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Jobs from="home" />
                  </motion.div>
                }
              />
              <Route path="/customers" element={<Customers />} />
              <Route path="/invoices" element={<Invoices />} />

              <Route
                path="/admin/device-types"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <DeviceTypes />
                  </motion.div>
                }
              />
              <Route
                path="/admin/brands"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Brands />
                  </motion.div>
                }
              />
              <Route
                path="/admin/devices"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <DevicesList />
                  </motion.div>
                }
              />
              <Route
                path="/admin/items"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Items />
                  </motion.div>
                }
              />
              <Route
                path="/admin/technicians"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Technicians />
                  </motion.div>
                }
              />

              <Route
                path="/settings/company"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {adminAuthenticated ? (
                      <Company setAdminAuthenticated={setAdminAuthenticated} />
                    ) : (
                      <AdminPinDialog setAdminAuthenticated={setAdminAuthenticated} />
                    )}
                  </motion.div>
                }
              />
              <Route
                path="/settings/invoice"
                element={
                  <motion.div
                    initial={{ x: -130 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {adminAuthenticated ? (
                      <Invoice setAdminAuthenticated={setAdminAuthenticated} />
                    ) : (
                      <AdminPinDialog setAdminAuthenticated={setAdminAuthenticated} />
                    )}
                  </motion.div>
                }
              />
              <Route path="/settings/labels" element={<Labels />} />
            </Routes>
          </Container>
        </Box>
      </Box>
      {backdropVisible && <Backdrop backdropVisible={backdropVisible} />}
    </BrowserRouter>
  );
}

export default Home;
