export const setCompanyDetails = (attribute, value) => {
    return {
      type: "SET_COMPANY_DETAILS",
      attribute,
      value,
    };
  };

export const setGeneralInvoice = (attribute, value) => {
    return {
      type: "SET_GENERAL_INVOICE",
      attribute,
      value,
    };
  };
  
export const setEstimationInvoice = (attribute, value) => {
    return {
      type: "SET_ESTIMATION_INVOICE",
      attribute,
      value,
    };
  };