import { Divider, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import ImageViewer from "./ImageViewer";
import { useTranslation } from "react-i18next";

function DeviceProperties({ device }) {
  const { t } = useTranslation();
  const [viewPic, setViewPic] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [imageLodaing, setImageLoading] = useState(true);

  return (
    <div>
      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Device")}
      </Typography>
      <br />
      <Typography variant="subtitle1">{device.name}</Typography>
      <Typography variant="subtitle1">{t("Serial")}: {device.serial}</Typography>
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />

      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Memory")}
      </Typography>
      <Typography variant="subtitle1">{device.memory}</Typography>
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />

      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Accessories")}
      </Typography>
      {device.accessories.map((d, i) => (
        <div key={i}>
          <Typography variant="subtitle1">{d}</Typography>
        </div>
      ))}
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />

      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Defects")}
      </Typography>
      {device.defects.map((d, i) => (
        <div key={i}>
          <Typography variant="subtitle1">{d}</Typography>
        </div>
      ))}
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />

      <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
        {t("Password / Pattern")}
      </Typography>
      <Typography variant="subtitle1">{device.password}</Typography>
      <Divider sx={{ marginBottom: "10px", marginTop: "5px" }} />

      {device.photos.length > 0 ? (
        <>
          <Typography variant="overline" sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}>
            {t("Photos")}
          </Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {device.photos.map((p, i) => (
              <div key={i} style={{ cursor: "pointer" }}>
                {imageLodaing && <Skeleton variant="rectangular" sx={{ paddingTop: "50%" }} />}
                <img
                  src={p}
                  alt="phone snaps"
                  style={{ maxWidth: "100%" }}
                  onLoad={device.photos.length === i + 1 ? () => setImageLoading(false) : null}
                  onClick={() => {
                    setSelectedImg(p);
                    setViewPic(true);
                  }}
                />
              </div>
            ))}
          </div>
        </>
      ) : null}

      {viewPic && <ImageViewer img={selectedImg} setViewPic={setViewPic} viewPic={viewPic} />}
    </div>
  );
}

export default DeviceProperties;
