import Home from "./pages/Home/Home";
import "./App.css";

import { useSelector, useDispatch } from "react-redux/es/exports";

import LoginScreen from "./pages/Login/LoginScreen";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { verifyAuth } from "./Redux/Actions";
import { loadFromDatabase } from "./Redux/Actions/fireactions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const databaseLoading = useSelector((state) => state.job.databaseLoading);
  const userAuthorized = useSelector((state) => state.user.userAuthorized);
  const loading = useSelector((state) => state.user.verifying);

  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    dispatch(verifyAuth());
    if (userAuthorized) {
      dispatch(loadFromDatabase());
    }

    return () => {};
  }, [userAuthorized]);

  useEffect(() => {
    function changeStatus() {
      setOnline(navigator.onLine);
    }
    // console.log("navigator :>> ", navigator);
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);



  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {online ? null : (
          <div className="offline-text">
            {t("You are Offline ! Please check internet connection")}
          </div>
        )}
        {loading || databaseLoading ? (
          <div className="main-loading">
            <CircularProgress color="primary" />
          </div>
        ) : userAuthorized ? (
          <Home />
        ) : (
          <LoginScreen />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
