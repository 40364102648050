import { Typography } from '@mui/material';
import { padding } from '@mui/system';
import React from 'react';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './Label.css';

const dateTimeOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZone: 'Europe/Berlin',
};

export const Label = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const currentJob = useSelector((state) => state.job.currentJob);
  const device = currentJob.device;
  const totals = currentJob.totals;
  const services = currentJob.jobServices;
  let haveProducts = false;
  if (currentJob.extraItems.length > 0) {
    haveProducts = true;
  }

  const getDynamicPadding = () => {
    if (device?.notes.length > 150) {
      return '2px';
    } else if (device?.notes.length > 100) {
      return '4px';
    } else if (device?.notes.length > 50) {
      return '6px';
    } else {
      return '8px';
    }
  };

  return (
    <div className="label-box" ref={ref}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: haveProducts ? '80%' : '100%' }}>
          <div className="label-row">
            <div className="label-col" style={{ width: '60%' }}>
              <div
                className="label-col-item"
                style={{
                  borderRight: '1px Solid',
                  borderBottom: '1px solid',
                  padding: getDynamicPadding(),
                }}
              >
                <div>
                  <Barcode
                    value={currentJob.jobNumber}
                    height={20}
                    margin={0.5}
                    width={haveProducts ? 1.3 : 1.5}
                    fontSize={12}
                    text={`AUFTRAG ${currentJob.jobNumber}`}
                  />
                  <div className={'customer-name-label'}>
                    {currentJob?.customer?.name}&nbsp;{currentJob?.customer?.phone}
                  </div>
                  <div className={'customer-name-label'}></div>
                </div>
              </div>
            </div>
            <div className="label-col" style={{ width: '40%', borderBottom: '1px solid' }}>
              <div
                className="label-col-item"
                style={{ fontSize: haveProducts ? 9 : 12, padding: getDynamicPadding() }}
              >
                <b>{t('Password / Pattern')}</b>
                <br /> {device.password}
              </div>
            </div>
          </div>
          <div className="label-row">
            <div className="label-col" style={{ width: '60%' }}>
              <div
                className="label-col-item"
                style={{
                  borderRight: '1px Solid',
                  borderBottom: '1px solid',
                  padding: getDynamicPadding(),
                }}
              >
                <Barcode
                  value={new Intl.NumberFormat('de-DE', {
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                  }).format(totals.total)}
                  height={20}
                  margin={1}
                  width={haveProducts ? 1.3 : 1.5}
                  fontSize={12}
                  text={`PREIS ${new Intl.NumberFormat('de-DE', {
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                  }).format(totals.total)} €`}
                />
              </div>
            </div>
            <div className="label-col" style={{ width: '40%', borderBottom: '1px solid' }}>
              <div
                className="label-col-item"
                style={{ fontSize: haveProducts ? 9 : 12, padding: getDynamicPadding() }}
              >
                <b>{t('Return Date')}</b>
                <br />
                {currentJob.returnDate
                  ? currentJob.returnDate instanceof Date
                    ? new Date(currentJob.returnDate).toLocaleDateString('de-DE', dateTimeOptions)
                    : new Date(currentJob.returnDate.seconds * 1000).toLocaleDateString(
                        'de-DE',
                        dateTimeOptions
                      )
                  : new Date(currentJob.returnDate).toLocaleDateString('de-DE', dateTimeOptions)}
              </div>
            </div>
          </div>
        </div>

        {haveProducts && (
          <div style={{ borderLeft: '1px solid', borderBottom: '1px solid', padding: 5 }}>
            <div>
              <b>Produkte:</b>
              {currentJob.extraItems.map((item, index) => (
                <div key={index} style={{ fontSize: 9, textAlign: 'left' }}>
                  + {item.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          textAlign: 'center',
          lineHeight: '1',
          fontSize: '11px',
          marginTop: '5px',
        }}
      >
        <span style={{ fontSize: '10px' }}>({device?.name})&nbsp;</span>
        <b>{services.map((d, i) => d.name + (i < services.length - 1 ? ' + ' : ''))}</b>
      </div>
      <div style={{ textAlign: 'center', lineHeight: '1', fontSize: '11px', marginTop: '5px' }}>
        {device?.notes}
      </div>
    </div>
  );
});
