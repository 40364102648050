import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../Redux/Actions";
import { NavLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { logout } from "../../firebase/config";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     fontSize: "1.1rem",
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(5)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "28ch",
//     },
//   },
// }));

const linkStyle = {
  textDecoration: "none",
  color: "white",
  fontSize: "1.1rem",
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar(props) {
  const { t } = useTranslation();
  const { open, toggleDrawer } = props;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langMenu, setLangMenu] = React.useState(null);
  const currentUser = useSelector((state) => state.user.currentUser);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLanguageMenu = (event) => {
    setLangMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLanguage = () => {
    setLangMenu(null);
  };

  const handleLogout = () => {
    handleClose();
    logout().then((res) => {
      console.log("logout successful", res);
      dispatch(actions.setCurrentUser(null));
      navigate("/", { replace: true });
    });
  };

  const handleSearchButton = () => {
    dispatch(actions.setJobsSearchActive(true));
  };

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h5"
          color="inherit"
          align="left"
          noWrap
          sx={{ flexGrow: 1 }}
          style={{ textTransform: "capitalize" }}
        >
          {location.pathname === "/"
            ? "Dashboard"
            : t(location.pathname.split("/")[location.pathname.split("/").length - 1])}
        </Typography>
        {/* <Search>
          <SearchIconWrapper>
            <SearchIcon fontSize="large" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="IMEI, ticket no., customer..."
            inputProps={{ "aria-label": "search" }}
            autofill={"off"}
            value={jobsSearchText}
            onChange={(e) => dispatch(actions.setJobsSearchText(e.target.value))}
          />
        </Search> */}
        <NavLink to="/jobs" style={linkStyle}>
          <IconButton
            color="inherit"
            size="large"
            sx={{ marginRight: "16px" }}
            onClick={handleSearchButton}
          >
            <SearchIcon fontSize="large" />
          </IconButton>
        </NavLink>
        <Button
          variant="outlined"
          color="inherit"
          size="large"
          sx={{ marginRight: "16px" }}
          onClick={() => dispatch(actions.setBackdropVisible(true, "job wizard", "adding"))}
          startIcon={<Add fontSize="large" />}
        >
          {t("Repair Job")}
        </Button>
        <IconButton
          color="inherit"
          size="large"
          sx={{ marginRight: "16px" }}
          onClick={handleLanguageMenu}
        >
          <Badge badgeContent={i18n.language === "en" ? "EN" : "DE"} color="secondary">
            <LanguageIcon fontSize="large" />
          </Badge>
        </IconButton>
        <Menu
          id="menu-language"
          anchorEl={langMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(langMenu)}
          onClose={handleCloseLanguage}
        >
          <MenuItem
            selected={i18n.language === "en"}
            onClick={() => {
              i18n.changeLanguage("en");
              handleCloseLanguage();
            }}
          >
            English
          </MenuItem>
          <MenuItem
            selected={i18n.language === "de"}
            onClick={() => {
              i18n.changeLanguage("de");
              handleCloseLanguage();
            }}
          >
            Deutsch
          </MenuItem>
        </Menu>
        <IconButton color="inherit" size="large" onClick={handleMenu}>
          <Badge color="secondary">
            <PersonIcon fontSize="large" />
          </Badge>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>{currentUser ? currentUser.email : null}</MenuItem>
          <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
