import { Add, Close } from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addItems } from "../../../Redux/Actions";

function AddItem() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [items, setItems] = useState([
    {
      name: "",
      price: 0,
      stock: 0,
    },
  ]);

  const handleAdd = () => {
    dispatch(addItems(items, true));
  };

  const handleItemInputs = (e, item, index, attribute) => {
    const newItems = items.map((s, i) => {
      if (i === index) {
        return {
          ...item,
          [attribute]: attribute === "name" ? e.target.value : e.target.valueAsNumber,
        };
      } else return s;
    });
    setItems(newItems);
  };

  return (
    <div>
      <Paper
        sx={{ width: "50%", margin: "50px auto", padding: "30px", backgroundColor: "#f6f6f6" }}
      >
        <Typography variant="h5" gutterBottom>
          {t("ADD NEW ITEMS")}
        </Typography>

        <Divider />

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          {items.length > 0 ? (
            <Typography variant="overline" sx={{ color: "#00000099", fontSize: "1rem" }}>
              {t("Items")}
            </Typography>
          ) : null}
          {items.map((s, i) => (
            <div style={{ display: "flex", justifyConent: "space-between" }} key={i}>
              <TextField
                variant="filled"
                label={t("Item")}
                margin="dense"
                sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "65%" }}
                value={s.name}
                onChange={(e) => handleItemInputs(e, s, i, "name")}
              />
              <TextField
                variant="filled"
                sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "20%" }}
                label={t("Price")}
                margin="dense"
                type={"number"}
                value={s.price}
                onChange={(e) => handleItemInputs(e, s, i, "price")}
                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
              />
              <TextField
                variant="filled"
                label={t("Stock")}
                sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "15%" }}
                type={"number"}
                margin="dense"
                value={s.stock}
                onChange={(e) => handleItemInputs(e, s, i, "stock")}
              />
              {items.length > 1 ? (
                <IconButton
                  sx={{ width: "10%" }}
                  onClick={() => setItems(items.filter((s, index) => index !== i))}
                >
                  <Close color="error" />
                </IconButton>
              ) : null}
            </div>
          ))}
          <Button
            fullWidth
            onClick={() =>
              setItems([
                ...items,
                {
                  name: "",
                  price: 0,
                  stock: 0,
                },
              ])
            }
            startIcon={<Add />}
          >
            {t("Add More")}
          </Button>
        </div>

        <Divider />

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" size="large" fullWidth onClick={handleAdd}>
            {items.length > 1 ? t("Add Items") : t("Add Item")}
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default AddItem;
