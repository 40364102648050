import * as actions from "../Actions";

const initialState = {
  currentUser: {},
  verifying: false,
  userAuthorized: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.loginStart().type:
      return {
        ...state,
        verifying: true,
      };

    case actions.loginSuccess().type:
      return {
        ...state,
        currentUser: action.user,
        userAuthorized: true,
        verifying: false,
        error: null,
      };
    case actions.loginFailed().type:
      return {
        ...state,
        currentUser: null,
        userAuthorized: false,
        verifying: false,
        error: action.err,
      };

    case actions.setCurrentUser().type:
      return {
        ...state,
        currentUser: action.user,
        userAuthorized: action.user ? true : false,
      };
    case actions.verifyStart().type:
      return {
        ...state,
        verifying: true,
      };
    case actions.verifySuccess().type:
      return {
        ...state,
        userAuthorized: true,
        verifying: false,
      };
    case actions.verifyFailed().type:
      return {
        ...state,
        userAuthorized: false,
        verifying: false,
      };

    default:
      return state;
  }
};
export default userReducer;
