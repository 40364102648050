import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import SignaturePad from 'react-signature-canvas';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel, Done, Save } from '@mui/icons-material';
import { setCustomerSignatures } from '../../../Redux/Actions';
import { useTranslation } from 'react-i18next';
import './Signature.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignatureDialog({ signatureDialogVisible, setSignatureDialogVisible }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company.companyDetails);
  const trimmedData = useSelector((state) => state.job.currentJob.customer.signatures);
  const invoice = useSelector((state) => state.company.generalInvoice);
  const [empty, setEmpty] = React.useState(true);
  const [saved, setSaved] = React.useState(false);
  //   const [trimmedData, settrimmedData] = useState(signatures);
  let sigPad = {};

  const clear = () => {
    if (sigPad.clear) {
      sigPad.clear();
    }
    setEmpty(true);
    dispatch(setCustomerSignatures(null));
  };

  const trim = () => {
    dispatch(setCustomerSignatures(sigPad.getTrimmedCanvas().toDataURL('image/png')));
    setSaved(true);
  };

  const handleClose = () => {
    document.exitFullscreen();
    setSignatureDialogVisible(false);
  };

  const handleSave = () => {
    if (trimmedData !== null) {
      trim();
    }
    document.exitFullscreen();
    setSignatureDialogVisible(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={signatureDialogVisible}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t("Customer Signatures")}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={handleSave}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <div style={{ padding: '10px 40px' }}>
          <div>
            <div>
              <p style={{ whiteSpace: 'pre-line', fontSize: '18px', lineHeight: '20px' }}>
                {invoice.termsConditions}
              </p>
            </div>
            <div
              style={{
                textAlign: 'right',
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ fontSize: '20px', textAlign: 'left' }}>
                <b>
                {`Mit meiner Unterschrift bestätige ich den Auftrag und stimme den AGB der Firma
                ${company.name} zu`}
                .&nbsp;
                </b>
              </div>
            </div>
          </div>
          <div className="mainContainer">
            <div className="sigContainer">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {trimmedData === null ? (
                  <div>
                    <SignaturePad
                      canvasProps={{ className: 'sigPad' }}
                      ref={(ref) => {
                        sigPad = ref;
                      }}
                      onEnd={() => setEmpty(false)}
                    />
                    <p>{t("Please Sign here")}</p>
                  </div>
                ) : (
                  <img className="sigImage" src={trimmedData} alt="signature" />
                )}
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={empty || trimmedData !== null}
                    sx={{ fontSize: '4rem' }}
                    color="primary"
                    onClick={trim}
                    style={{ margin: '5px', padding: '53px 20px' }}
                    startIcon={<Done style={{ fontSize: '4rem' }} />}
                  >
                    {'Ok'}
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    sx={{ fontSize: '4rem' }}
                    disabled={empty && trimmedData === null}
                    color="secondary"
                    onClick={clear}
                    style={{ margin: '5px', padding: '53px 20px' }}
                    startIcon={<Cancel style={{ fontSize: '4rem' }} />}
                  >
                    {t('Clear')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
