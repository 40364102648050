import { configureStore} from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import rootReducer from "./Reducers/rootReducer";

const logger = (store) => {
  return (next) => {
    return (action) => {
      const result = next(action);
      return result;
    };
  };
};

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(logger),
});

export const persistor = persistStore(store);

// export default { store, persistor };
