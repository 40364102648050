import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConstructionIcon from "@mui/icons-material/Construction";
import { useTranslation } from "react-i18next";

const linkStyle = {
  textDecoration: "none",
  color: "inherit",
  fontSize: "1.1rem",
};

export default function DrawerList() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <React.Fragment>
      <NavLink to="/" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/"}>
          <ListItemIcon>
            <DashboardIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Dashboard")} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/jobs" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/jobs"}>
          <ListItemIcon>
            <ConstructionIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary={t("Jobs")} />
        </ListItemButton>
      </NavLink>
      {/* <NavLink to="/customers" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/customers"}>
          <ListItemIcon>
            <PeopleIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary="Customers" />
        </ListItemButton>
      </NavLink>
      <NavLink to="/invoices" style={linkStyle}>
        <ListItemButton selected={location.pathname === "/invoices"}>
          <ListItemIcon>
            <AssignmentIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText disableTypography={true} primary="Invoices" />
        </ListItemButton>
      </NavLink> */}
    </React.Fragment>
  );
}
