import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CancelIcon from '@mui/icons-material/Cancel';

import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { setModelSearchInput } from '../../../Redux/Actions';
import { useTranslation } from 'react-i18next';

// import { createFilterOptions } from "@mui/material/Autocomplete";

// const filter = createFilterOptions();

export default function SearchInput(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { devicesFullName, setFilteredDevices, setNewDevice } = props;

  const modelSearchInput = useSelector((state) => state.job.modelSearchInput);

  const handleInput = (val) => {
    dispatch(setModelSearchInput(val));
    const reVal = val.toLowerCase();
    const filtered = devicesFullName.filter((d) => d.fullName.toLowerCase().includes(reVal));
    setFilteredDevices(filtered);
    if (filtered.length === 0) {
      setNewDevice(val);
    }
  };

  React.useEffect(() => {
    const inputVal = modelSearchInput.toLowerCase();
    const filtered = devicesFullName.filter((d) => d.fullName.toLowerCase().includes(inputVal));
    setFilteredDevices(filtered);
    if (filtered.length === 0) {
      setNewDevice(modelSearchInput);
    }

    return () => {};
  }, [devicesFullName]);

  return (
    <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
      <OutlinedInput
        value={modelSearchInput}
        defaultValue={''}
        autoComplete="off"
        onChange={(e) => handleInput(e.target.value)}
        sx={{
          width: 450,
          color: 'white',
          margin: '0 auto 30px auto',
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
          '& .MuiOutlinedInput-input': { fontSize: '1.4rem', color: 'white' },
          '& .MuiAutocomplete-endAdornment': { color: 'white' },
        }}
        id="device-model-search"
        placeholder={t('Manufacturer, Model...')}
        variant="outlined"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="large" sx={{ color: 'white' }} />
          </InputAdornment>
        }
        endAdornment={
          modelSearchInput.length > 0 ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input"
                onClick={() => {
                  dispatch(setModelSearchInput(''));
                  setFilteredDevices(devicesFullName);
                }}
                edge="end"
              >
                <CancelIcon fontSize="large" sx={{ color: 'white' }} />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
    </FormControl>
  );
}
