import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

import jobReducer from "./job";
import companyReducer from "./company";
import userReducer from "./user";

const rootpPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: [""],
};

const jobPersistConfig = {
  key: "job",
  storage: storage,
  whitelist: [],
};

const companyPersistConfig = {
  key: "company",
  storage: storage,
  blacklist: [],
};

const userPersistConfig = {
  key: "user",
  storage: storageSession,
  whitelist: [],
};

const rootReducer = combineReducers({
  job: persistReducer(jobPersistConfig, jobReducer),
  company: persistReducer(companyPersistConfig, companyReducer),
  user: persistReducer(userPersistConfig, userReducer),
});

export default persistReducer(rootpPersistConfig, rootReducer);
