import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCustomers,
  getCustomersByQuery,
  setCustomers,
} from '../../../Redux/Actions/fireactions';
import { setAutoCustomerDetails } from '../../../Redux/Actions';
import { IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { t } from 'i18next';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function CustomerNameAutocomplete({
  handleInputs,
  label,
  oldCustomer,
  setOldCustomer,
}) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.job.customers);
  const customer = useSelector((state) => state.job.currentJob.customer);
  //   const [options, setOptions] = React.useState(customers);
  const loading = useSelector((state) => state.job.customersLoading);

  const [query, setQuery] = React.useState('');

  React.useEffect(() => {
    if (customers.length === 0) {
      // dispatch(getCustomers());
    }

    return () => {};
  }, []);

  //   React.useEffect(() => {
  //     if (!open) {
  //       setOptions([]);
  //     }
  //   }, [open]);

  const querySearching = (e) => {
    const str = e.target.value;
    handleInputs('name', str);
    console.log('queryText :>> ', str);
    if (str.length > 2) {
      dispatch(getCustomersByQuery(str.charAt(0).toUpperCase() + str.slice(1)));
    }
    if (str.length === 0) {
      dispatch(setCustomers([]));
    }
  };

  const handleSelectingCustomer = (event, option) => {
    console.log('option', option);
    setOldCustomer(true);
    dispatch(setAutoCustomerDetails(option));
    console.log('check key', option.hasOwnProperty('id'));

    setOpen(false);
  };

  const handleClear = () => {
    const option = null;
    setOldCustomer(false);
    dispatch(setAutoCustomerDetails(option));
    dispatch(setCustomers([]));
  };

  return (
    <Autocomplete
      id="customer-name-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      inputValue={customer.name}
      freeSolo
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={customers}
      loading={loading}
      clearIcon={
        <IconButton onClick={handleClear} sx={{ marginTop: '-10px' }}>
          <Clear />
        </IconButton>
      }
      renderInput={(params) => (
        <TextField
          sx={{ backgroundColor: oldCustomer ? '#faebd7' : '#fff' }}
          onChange={(e) => querySearching(e)}
          placeholder={t('Start typing to search...')}
          label={label}
          fullWidth
          required
          margin="normal"
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id} onClick={(e) => handleSelectingCustomer(e, option)}>
            {option.name}
          </li>
        );
      }}
    />
  );
}
