import React from 'react';

import { DateRangePicker } from 'react-date-range';
import { de } from 'react-date-range/dist/locale/index';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


const DatePicker = (props) => {
  const { dates, setDates } = props;

  return (
    <div
      style={{
        padding: '10px',
        textAlign: 'center',
        margin: '10px 0 10px 0',
      }}
    >
      <DateRangePicker
        locale={de}
        onChange={(item) => setDates([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={dates}
        direction="horizontal"
        maxDate={new Date()}
      />
    </div>
  );
};
export default DatePicker;
