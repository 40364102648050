import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        light: process.env.REACT_APP_PRIMARY_CLR_LIGHT,
        main: process.env.REACT_APP_PRIMARY_CLR_MAIN,
        dark: process.env.REACT_APP_PRIMARY_CLR_DARK,
        contrastText: process.env.REACT_APP_PRIMARY_CONTRAST_TEXT || "#fff",
      },
      secondary: {
        light: process.env.REACT_APP_SECONDARY_CLR_LIGHT,
        main: process.env.REACT_APP_SECONDARY_CLR_MAIN,
        dark: process.env.REACT_APP_SECONDARY_CLR_DARK,
        contrastText:process.env.REACT_APP_SECONDARY_CONTRAST_TEXT || "#fff",
      },
    },
  }); 

  export default theme;