import * as React from "react";

import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import { Cancel } from "@mui/icons-material";

export default function SimpleBackdrop({ img, setViewPic, viewPic }) {
  const [open, setOpen] = React.useState(viewPic);
  //   const [imageLoaded, setImageLoaded] = React.useState(false);
  const handleClose = (e) => {
    if (e.target.classList.contains("backdrop-all")) {
      setOpen(false);
      setViewPic(false);
    }
  };

  //   const handleDownload = () => {
  //     saveAs(img.url);
  //   };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        className="backdrop-all"
        onClick={handleClose}
      >
        <img
          style={{ maxHeight: "90%", maxWidth: "95%", marginTop: "30px" }}
          src={img}
          alt="device-pic"
        />
        <div style={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconButton size="large" onClick={() => setViewPic(false)} sx={{ color: "white" }}>
            <Cancel fontSize="large" />
          </IconButton>
        </div>
      </Backdrop>
    </div>
  );
}
