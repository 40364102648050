import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataGrid, deDE, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import '../../../App.css';
import { Button, Icon, IconButton, LinearProgress } from '@mui/material';
import { Delete, People, ReplayOutlined, SavedSearch } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';
import { getCustomers, setBackdropVisible, setCurrentJob } from '../../../Redux/Actions';
import { updateCustomer } from '../../../Redux/Actions/fireactions';
import { useTranslation } from 'react-i18next';
import SearchFilter from './SearchFilter';

function CustomToolbar(props, showDelete, setShowDelete, showSearchFilter, setShowSearchFilter) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="Table-toolbar">
      <Icon fontSize="large" className="Table-icon">
        <People fontSize="large" style={{ color: 'white' }} />
      </Icon>
      <div
        className="Table-icon-bg"
        style={{
          background: process.env.REACT_APP_PRIMARY_CLR_DARK,
        }}
      ></div>

      <Typography component="h1" variant="h4" color="secondary">
        {/* Repairs */}
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowSearchFilter(!showSearchFilter)}
          startIcon={<SavedSearch />}
        >
          {t('Search All')}
        </Button>
        <GridToolbarQuickFilter
          debounceMs={500}
          variant="outlined"
          placeholder={t('Name') + ',' + t('Phone') + ',' + t('Street') + '...'}
        />
        {/* <IconButton
          fontSize={'large'}
          sx={{ marginLeft: '10px' }}
          onClick={() => dispatch(getCustomers())}
        >
          <ReplayOutlined fontSize={'large'} color="primary" />
        </IconButton> */}
        {/* <IconButton
          fontSize={"large"}
          sx={{ marginLeft: "10px" }}
          onClick={() => setShowDelete(!showDelete)}
        >
          <Delete fontSize={"large"} color="primary" />
        </IconButton> */}
      </div>
    </div>
  );
}

export default function Customers(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.job.customers);
  const customersLoading = useSelector((state) => state.job.customersLoading);
  // const jobs = useSelector((state) => state.job.jobs);

  const [showSearchFilter, setShowSearchFilter] = useState(true);

  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteDevice = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('row :>> ', params);
    // dispatch(deleteItem(params.id));
  };

  const getFullAddress = (customer) => {
    return `${customer.row.street} ${customer.row.houseNo}, ${customer.row.postalCode}, ${customer.row.city}`;
  };

  // const showJobDetails = (row) => {
  //   console.log('jobs', jobs);
  //   dispatch(setCurrentJob(jobs.filter((j) => j.id === row.row.jobId)[0]));
  //   dispatch(setBackdropVisible(true, 'job details', 'viewing'));
  // };

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'name',
      headerName: t('Name'),
      editable: true,
      headerClassName: 'table-header-logo',
      flex: 2,
    },
    {
      field: 'street',
      headerName: t('Street'),
      editable: true,
      headerClassName: 'table-header',
      flex: 3,
    },
    {
      field: 'houseNo',
      headerName: t('House No.'),
      editable: true,
      headerClassName: 'table-header',
      flex: 1,
    },
    {
      field: 'postalCode',
      headerName: t('Postal Code'),
      editable: true,
      headerClassName: 'table-header',
      flex: 1,
    },
    {
      field: 'city',
      headerName: t('City'),
      editable: true,
      headerClassName: 'table-header',
      flex: 2,
    },
    {
      field: 'phone',
      headerName: t('Phone'),
      editable: true,
      headerClassName: 'table-header',
      flex: 2,
    },
    // {
    //   field: 'jobId',
    //   headerName: 'Job Order',
    //   headerClassName: 'table-header',
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Button variant="outlined" onClick={() => showJobDetails(params)}>
    //       View
    //     </Button>
    //   ),
    // },
    {
      field: 'delete',
      headerName: 'Delete',
      headerClassName: 'table-header',
      flex: 1,
      hide: !showDelete,
      renderCell: (params) => (
        <IconButton onClick={(e) => handleDeleteDevice(e, params)}>
          <Delete color="error" />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    // dispatch(getCustomers());
  }, []);

  const handleCellEdit = (data) => {
    console.log('data :>> ', data);
    console.log(data.value);
    const customer = customers.find((c) => c.id === data.id);
    if (customer[data.field] !== data.value) {
      dispatch(updateCustomer(data.id, data.field, data.value));
    }
  };

  const rows = customers.map((c, i) => c);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1, background: 'white' }}>
        <SearchFilter
          showSearchFilter={showSearchFilter}
          setShowSearchFilter={setShowSearchFilter}
        />
        <DataGrid
          loading={customersLoading}
          rows={rows}
          columns={columns}
          onCellEditCommit={(data) => handleCellEdit(data)}
          headerHeight={65}
          pageSize={100}
          keyboard
          disableSelectionOnClick
          components={{
            Toolbar: () =>
              CustomToolbar(
                props,
                showDelete,
                setShowDelete,
                showSearchFilter,
                setShowSearchFilter
              ),
            LoadingOverlay: LinearProgress,
          }}
          sx={{
            minHeight: '86vh',
            padding: '15px',
            fontSize: '1.1rem',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .table-header': {
              marginTop: '10px',
            },
            '& .dtype-cell': {
              color: 'grey',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row': {
              maxHeight: '300px !important',
            },
            '& .MuiDataGrid-cell': {
              maxHeight: '300px !important',
              padding: '10px',
              fontSize: '1.4rem',
            },
            '& .MuiDataGrid-editInputCell': {
              fontSize: '1.4rem',
            },
            // "& .MuiDataGrid-columnHeaderTitle": {
            //   fontWeight: "bold",

            // },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
        />
      </div>
    </div>
  );
}
