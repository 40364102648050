import * as React from "react";

import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import {
  Computer,
  DesktopMac,
  DesktopWindows,
  DevicesOther,
  LaptopMac,
  PhoneIphone,
  Tablet,
} from "@mui/icons-material";

const chipsContainer = {
  padding: "20px 5px 10px 5px",
  borderRadius: "5px",
  display: "inline-flex",
  flexWrap: "wrap",
  justifyContent: "center",
  width: "90%",
};

const chipStyle = {
  fontSize: "1.2rem",
  height: "45px",
  paddingLeft: "15px",
  paddingRight: "15px",
  margin: "0 10px 10px 10px",
};

const icons = (index) => {
  switch (index) {
    case 0:
      return <PhoneIphone />;
    case 1:
      return <Tablet />;
    case 2:
      return <DesktopMac />;
    case 3:
      return <LaptopMac />;
    case 4:
      return <DesktopWindows />;
    case 5:
      return <Computer />;
    case 6:
      return <DevicesOther />;

    default:
      break;
  }
};

export default function DeviceTypeSelection({ brand, setBrand }) {
  const dTypes = useSelector((state) => state.job.deviceTypes);

  // console.log("dtypes :>> ", brand.deviceTypes);

  const handleMultiSelection = (val, from) => {
    // console.log("val :>> ", val);
    if (brand.deviceTypes.includes(val)) {
      setBrand({ ...brand, deviceTypes: brand.deviceTypes.filter((a) => a !== val) });
    } else {
      setBrand({ ...brand, deviceTypes: [...brand.deviceTypes, val] });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={chipsContainer}>
        {dTypes.map((d, i) => (
          <Chip
            label={d.name}
            key={i}
            icon={icons(i)}
            clickable
            onDelete={brand.deviceTypes.includes(d) ? () => handleMultiSelection(d) : null}
            sx={chipStyle}
            onClick={() => handleMultiSelection(d)}
            color={brand.deviceTypes.includes(d) ? "primary" : "default"}
          />
        ))}
      </div>
    </div>
  );
}
