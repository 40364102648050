import { FileUpload } from "@mui/icons-material";
import { IconButton, Skeleton, Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import Resizer from "react-image-file-resizer";

import { imgStorage } from "../../../firebase/config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useDispatch } from "react-redux";
import { updateBrand } from "../../../Redux/Actions";

import "./Logo.css";

function Logo({ params, from, brand, setBrand }) {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [logoUploading, setLogoUploading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const logoUploadHandler = (img) => {
    // console.log("image :>> ", img.image);
    setImgLoading(true);

    const fileName = nanoid() + img.image.name;
    const storageRef = ref(imgStorage, `/brands/${fileName}`);
    const metadata = {
      cacheControl: "max-age=2628288",
      customMetadata: {
        imageClass: "brand-logo",
      },
    };
    const uploadTask = uploadBytesResumable(storageRef, img.image, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // let percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        //   setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((URL) => {
          from === "adding"
            ? setBrand({ ...brand, logo: URL })
            : dispatch(updateBrand(params.row.id, "logo", URL));
        });
        setLogoUploading(false);
      }
    );
    setError("");
  };

  const imageResizer = async (imageFile) => {
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        imageFile,
        250,
        250,
        imageFile.type === "image/png" ? "PNG" : "JPEG",
        50,
        0,
        (uri) => {
          // console.log("uri :>> ", uri);
          resolve(logoUploadHandler({ image: uri }));
        },
        "file"
      );
    });
  };

  const fileSelected = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    setLogoUploading(true);
    imageResizer(file);
  };

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          padding: "5px",
        }}
      >
        {params.row.logo === "" && !logoUploading && !imgLoading ? (
          <>
            <input
              accept="image/png,image/jpeg"
              id={params.row.id}
              style={{ display: "none" }}
              type="file"
              onChange={fileSelected}
            />
            <label htmlFor={params.row.id}>
              <IconButton size="large" component="span">
                <FileUpload fontSize="large" color="primary" />
              </IconButton>
            </label>
          </>
        ) : (
          <div className={"ImageContainer"}>
            <div className={"ImageMiddle"}>
              <input
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
                id={params.row.id}
                type="file"
                onChange={fileSelected}
              />
              <label htmlFor={params.row.id}>
                <IconButton size="large" component="span">
                  <FileUpload fontSize="large" color="primary" />
                </IconButton>
              </label>
            </div>

            {(logoUploading || imgLoading) && (
              <Skeleton variant="rectangular" sx={{ paddingTop: "80%", width: "70px" }} />
            )}
            <div
              className={"ImageDiv"}
              style={{
                display: logoUploading || imgLoading || params.row.logo === "" ? "none" : "block",
              }}
            >
              <img
                src={params.row.logo}
                onLoad={() => setImgLoading(false)}
                alt={params.row.name}
                width={70}
              />
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
}

export default Logo;
