import React, { useRef } from "react";

import { useDispatch } from "react-redux";

import { Edit, Print } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import DeviceProperties from "./DeviceProperties";
import { Invoice } from "../../Printings/Invoice";
import JobProperties from "./JobProperties";
import { setEditMode } from "../../../Redux/Actions";

import { useTranslation } from "react-i18next";
import { EstimationInvoice } from "../../Printings/EstimationInvoice";
import { Label } from "../../Printings/Label";

const invoiceStyle = `
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 5mm;
}

@media screen {
  div.divFooter {
    /* display: none; */
  }
}
`;

const labelStyle = `
@media print {
  @page { size: 375px 185px; margin: 0;}
}
`;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function JobDetails({ currentJob }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const invoiceRef = useRef();
  const labelRef = useRef();
  const estimationInvoiceRef = useRef();

  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,
    pageStyle: invoiceStyle,
  });

  const handlePrintLabel = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: labelStyle,
  });

  const handlePrintEstimation = useReactToPrint({
    content: () => estimationInvoiceRef.current,
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Item>
            <DeviceProperties device={currentJob.device} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "53%",
                margin: "auto",
              }}
            >
              <Button
                variant="outlined"
                onClick={
                  currentJob.status === "estimation"
                    ? () => handlePrintEstimation()
                    : () => handlePrintInvoice()
                }
                startIcon={<Print />}
              >
                {t("Invoice")}
              </Button>
              <Button
                variant="outlined"
                onClick={
                  currentJob.status === "estimation"
                    ? () => handlePrintEstimation()
                    : () => handlePrintLabel()
                }
                startIcon={<Print />}
              >
                {t("Label")}
              </Button>
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => dispatch(setEditMode())}
              >
                {t("Edit")}
              </Button>
            </div>
            <div style={{ boxShadow: "0px 0px 11px #0000002b" }}>
              {currentJob.status === "estimation" ? (
                <EstimationInvoice ref={estimationInvoiceRef} />
              ) : (
                <Invoice ref={invoiceRef} />
              )}
            </div>
              <div style={{ boxShadow: "0px 0px 11px #0000002b", margin:"20px auto", width:"fit-content" }}>
                <Label ref={labelRef} />
              </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item>
            <JobProperties currentJob={currentJob} />
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
