import { Add, Close, Done } from "@mui/icons-material";
import { Chip, Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBrandDeviceType, deleteBrandDeviceType } from "../../../Redux/Actions";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

const chipStyle = {
  fontSize: "1rem",
  height: "35px",
  paddingLeft: "10px",
  paddingRight: "10px",
  marginBottom: "5px",
  marginTop: "5px",
};

const BrandDeviceTypes = ({ params }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [adding, setAdding] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [dType, setDtype] = useState("");

  // console.log("dType :>> ", dType);

  const dTypes = useSelector((state) => state.job.deviceTypes);

  const dTypeIds = [...params.row.deviceTypes];

  const deviceTypes = dTypes.filter((d) => dTypeIds.includes(d.id));

  const notAssigned = dTypes.filter((d) => !dTypeIds.includes(d.id));

  const handleDelete = (dTypeId) => {
    dispatch(deleteBrandDeviceType(params.row.id, dTypeId));
  };
  const handleAdding = (rowId) => {
    setRowId(rowId);
    setAdding(true);
  };

  const handleDone = () => {
    dispatch(addBrandDeviceType(rowId, dType.id));
    setRowId(null);
    setAdding(false);
  };

  const handleChange = (e) => {
    setDtype(e.target.value);
  };

  const handleCancel = () => {
    setRowId(null);
    setAdding(false);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      tabIndex={params.tabIndex}
    >
      {/* {console.log("params", params)} */}
      {deviceTypes.map((d, i) => (
        <Chip
          label={d.name}
          key={i}
          clickable
          sx={chipStyle}
          onDelete={() => handleDelete(d.id)}
          // onClick={() => handleMultiSelection(m, "accessory")}
          color={"primary"}
        />
      ))}
      {adding && rowId === params.row.id ? (
        <FormControl sx={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label">{t("Device Type")}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dType}
            label="Device Type"
            onChange={handleChange}
          >
            {notAssigned.map((n, i) => (
              <MenuItem key={i} value={n}>
                {n.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      <Chip
        label={adding ? t("Done") : t("Add")}
        key="99"
        clickable
        onClick={() => (adding ? handleDone() : handleAdding(params.row.id))}
        sx={chipStyle}
        icon={adding ? <Done /> : <Add />}
        variant={"outlined"}
        color={adding ? "success" : "primary"}
      />
      {adding && (
        <Chip
          label={t("Cancel")}
          key="999"
          clickable
          onClick={handleCancel}
          sx={chipStyle}
          icon={<Close />}
          variant={"outlined"}
          color={"error"}
        />
      )}
    </Stack>
  );
};

export default BrandDeviceTypes;
