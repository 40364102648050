export const deviceTypesDB = [
  { id: 1, name: "Smartphone", count: 0, functionChecks: ["Display", "Camera", "Speaker", "BT"] },
  { id: 2, name: "Tablet", count: 0, functionChecks: ["Display", "Camera", "Speaker", "BT"] },
  { id: 3, name: "iMac", count: 0, functionChecks: ["Display", "Powe Cable", "Webcam","Memory", "Speakers"] },
  {
    id: 4,
    name: "Macbook",
    functionChecks: ["display", "Powe Cable", "Webcam", "USB port", "BT", "Speakers", "Keyboard"],
  },
  {
    id: 5,
    name: "Laptop",
    functionChecks: ["display", "Powe Cable", "webcam", "USB port", "BT", "Keyboard"],
  },
  {
    id: 6,
    name: "PC",
    functionChecks: ["Processor", "Graphics", "Power Supply", "USB port", "RAM"],
  },
  { id: 7, name: "Other", count: 0, functionChecks: [] },
];

// export const brandsDB = [
//   { id: 1, name: "Apple", count: 0 },
//   { id: 2, name: "Samsung", count: 0 },
//   { id: 3, name: "Sony", count: 0 },
//   { id: 4, name: "Dell", count: 0 },
//   { id: 5, name: "Microsoft", count: 0 },
// ];

// export const devicesDB = [
//   {
//     id: 1,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "Iphone XS",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//       { name: "LCD", price: 300 },
//       { name: "Speaker", price: 50 },
//     ],
//   },
//   {
//     id: 2,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "Iphone XS Max",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//       { name: "LCD", price: 300 },
//       { name: "Speaker", price: 50 },
//     ],
//   },
//   {
//     id: 3,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "Iphone 11 pro",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//       { name: "LCD", price: 300 },
//       { name: "Speaker", price: 50 },
//     ],
//   },
//   {
//     id: 4,
//     brandId: "B0uIclCCmaODssqI1t6q",
//     model: "Galaxy S9",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//       { name: "LCD", price: 300 },
//       { name: "Speaker", price: 50 },
//     ],
//   },
//   {
//     id: 5,
//     brandId: "B0uIclCCmaODssqI1t6q",
//     model: "Note 8",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//       { name: "Smart Pen", price: 100 },
//     ],
//   },
//   {
//     id: 6,
//     brandId: "B0uIclCCmaODssqI1t6q",
//     model: "Galaxy S22 Ultra",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//       { name: "BT", price: 150 },
//     ],
//   },
//   {
//     id: 7,
//     brandId: "PvbOVaJaIYIhP3euFl4i",
//     model: "Xperia 1 III",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//     ],
//   },
//   {
//     id: 8,
//     brandId: "PvbOVaJaIYIhP3euFl4i",
//     model: "Xperia 5",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//     ],
//   },
//   {
//     id: 9,
//     brandId: "PvbOVaJaIYIhP3euFl4i",
//     model: "Xperia Ace",
//     deviceTypeId: "ZH2QraFJuQyU5Dp5SlZW",
//     repairServices: [
//       { name: "Back Camera", price: 200 },
//       { name: "Front Camera", price: 100 },
//       { name: "Battery", price: 150 },
//     ],
//   },
//   {
//     id: 10,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "iPad Pro",
//     deviceTypeId: "WInX2arWooF1Tegfpnl1",
//     repairServices: [],
//   },
//   {
//     id: 11,
//     brandId: "B0uIclCCmaODssqI1t6q",
//     model: "Galaxy S Tab",
//     deviceTypeId: "WInX2arWooF1Tegfpnl1",
//     repairServices: [],
//   },
//   {
//     id: 12,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "iMac 2020",
//     deviceTypeId: "71fZnkYpK5E2kgHo7d7f",
//     repairServices: [],
//   },
//   {
//     id: 13,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "iMac 2021",
//     deviceTypeId: "71fZnkYpK5E2kgHo7d7f",
//     repairServices: [],
//   },
//   {
//     id: 14,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "Macbook Air 2019",
//     deviceTypeId: "31QwjGi2Zu64GqawZG0o",
//     repairServices: [],
//   },
//   {
//     id: 15,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "Macbook Pro 2016",
//     deviceTypeId: "31QwjGi2Zu64GqawZG0o",
//     repairServices: [],
//   },
//   {
//     id: 16,
//     brandId: "PvbOVaJaIYIhP3euFl4i",
//     model: "Vaio 2016",
//     deviceTypeId: "A1ZdOx7r2nyjbmiuWupS",
//     repairServices: [
//       { name: "display", price: 300 },
//       { name: "Webcam", price: 100 },
//     ],
//   },
//   {
//     id: 17,
//     brandId: "KOzTZdlLC0172lRk6V2e",
//     model: "Inspiron 15 2018",
//     deviceTypeId: "A1ZdOx7r2nyjbmiuWupS",
//     repairServices: [
//       { name: "display", price: 300 },
//       { name: "Webcam", price: 100 },
//     ],
//   },
//   {
//     id: 18,
//     brandId: "KOzTZdlLC0172lRk6V2e",
//     model: "core i5",
//     deviceTypeId: "Hal7dNWSZzIajvetgKSv",
//     repairServices: [],
//   },
//   {
//     id: 19,
//     brandId: "KOzTZdlLC0172lRk6V2e",
//     model: "core i7",
//     deviceTypeId: "Hal7dNWSZzIajvetgKSv",
//     repairServices: [],
//   },
//   {
//     id: 20,
//     brandId: "PvbOVaJaIYIhP3euFl4i",
//     model: "Playstation 4",
//     deviceTypeId: "m1NXcJ8EbhJIDtO27gsR",
//     repairServices: [],
//   },
//   {
//     id: 21,
//     brandId: "yVhTweIMPEbu4cRdkGrV",
//     model: "Xbox 360",
//     deviceTypeId: "m1NXcJ8EbhJIDtO27gsR",
//     repairServices: [],
//   },
//   {
//     id: 22,
//     brandId: "eDNttuY9JCM6kPfVvoOI",
//     model: "Watch 2018",
//     deviceTypeId: "m1NXcJ8EbhJIDtO27gsR",
//     repairServices: [],
//   },
// ];

export const memoriesDB = ["32GB", "64GB", "128GB", "256GB", "512GB", "ITB"];
export const accessoriesDB = ["Charger", "Case", "Earphones", "Packaging"];


export const itemsDB = [
  {
    id: 1,
    name:"Charger",
    price: 100,
    stock: 10,
  },
  {
    id: 2,
    name:"Earphones",
    price: 200,
    stock: 10,
  },
  {
    id: 3,
    name:"Case",
    price: 50,
    stock: 10,
  },
  {
    id: 4,
    name:"Screen protector",
    price: 10,
    stock: 10,
  },
  {
    id: 5,
    name:"Wireless Charger",
    price: 150,
    stock: 10,
  },
  {
    id: 6,
    name:"Mouse",
    price: 80,
    stock: 10,
  }
]