import React from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import { AddAPhoto, Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Cancel } from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

function TakePhotos({
  takingPic,
  setTakingPic,
  camImgs,
  setCamImgs,
  camImgUploadHandler,
  uploadCompleted,
  handleRemoveUploadedImage,
  uploadLoading,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [picTaken, setPicTaken] = React.useState(false);
  const handleClose = (e) => {
    if (e.target.classList.contains("image-backdrop")) {
      setOpen(false);
      setTakingPic(false);
      setPicTaken(true);
    }
  };

  function handleTakePhoto(dataUri) {
    setCamImgs([...camImgs, dataUri]);
    setPicTaken(true);
  }

  return (
    <div>
      {camImgs.length > 0 ? (
        <div>
          <div
            style={{
              display: "inline-flex",
              margin: "10px",
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {camImgs.map((c, index) => (
              <div key={index} style={{ border: "2px solid white" }}>
                <div style={{ textAlign: "right", marginBottom: "-50px", padding: "5px" }}>
                  <IconButton
                    sx={{ background: "#ffffff42" }}
                    onClick={() => handleRemoveUploadedImage(index, true)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </div>
                <img
                  src={camImgs[index]}
                  alt="cam-pic"
                  height="auto"
                  width="auto"
                  style={{ margin: "auto", maxWidth: "280px" }}
                />
              </div>
            ))}

            {uploadCompleted || uploadLoading ? null : (
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "space-around",
                  margin: "auto",
                }}
              >
                <IconButton
                  fontSize="large"
                  color="primary"
                  onClick={() => {
                    setPicTaken(false);
                    setTakingPic(true);
                  }}
                >
                  <AddAPhoto fontSize="large" color="primary" />
                </IconButton>
              </div>
            )}
          </div>

          {uploadCompleted || uploadLoading ? null : (
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                camImgUploadHandler();
                setPicTaken(true);
                setTakingPic(false);
              }}
              endIcon={<GridCheckCircleIcon fontSize="large" />}
            >
              {t("Done")}
            </Button>
          )}
        </div>
      ) : null}

      {picTaken ? null : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          className="image-backdrop"
          onClick={handleClose}
        >
          <div className="image-close-button">
            <IconButton size="large" onClick={() => setTakingPic(false)}>
              <Cancel fontSize="large" />
            </IconButton>
          </div>

          <div
            style={{
              display: "inline-flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri);
              }}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              idealResolution={{ width: 1920, height: 1080 }}
              imageType={IMAGE_TYPES.JPG}
              imageCompression={0.7}
              isMaxResolution={true}
              isImageMirror={false}
              isSilentMode={false}
              isDisplayStartCameraError={true}
              isFullscreen={false}
              sizeFactor={1}
            />
          </div>
        </Backdrop>
      )}
    </div>
  );
}

export default TakePhotos;
