import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataGrid, deDE, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import '../../../App.css';
import { Button, Icon, IconButton, LinearProgress } from '@mui/material';
import { Add, Category, Delete, ReplayOutlined } from '@mui/icons-material';
import { useState } from 'react';
import { deleteItem, getItems, updateItem } from '../../../Redux/Actions/fireactions';
import { setBackdropVisible } from '../../../Redux/Actions';
import { useTranslation } from 'react-i18next';

function CustomToolbar(props, showDelete, setShowDelete) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="Table-toolbar">
      <Icon fontSize="large" className="Table-icon">
        <Category fontSize="large" style={{ color: 'white' }} />
      </Icon>
      <div
        className="Table-icon-bg"
        style={{
          background: process.env.REACT_APP_PRIMARY_CLR_DARK,
        }}
      ></div>

      <Typography
        component="h1"
        variant="h4"
        color="secondary"
        // noWrap
        // sx={{ flexGrow: 1 }}
        // style={{ textTransform: "capitalize" }}
      >
        {/* Repairs */}
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <GridToolbarQuickFilter
          debounceMs={500}
          variant="outlined"
          placeholder={t('Search Item')}
        />
        <Button
          sx={{ fontSize: '1rem', marginLeft: '15px' }}
          startIcon={<Add />}
          variant="contained"
          onClick={() => dispatch(setBackdropVisible(true, 'add item', 'adding'))}
        >
          {t('Item')}
        </Button>
        <IconButton
          fontSize={'large'}
          sx={{ marginLeft: '10px' }}
          onClick={() => dispatch(getItems())}
        >
          <ReplayOutlined fontSize={'large'} color="primary" />
        </IconButton>
        <IconButton
          fontSize={'large'}
          sx={{ marginLeft: '10px' }}
          onClick={() => setShowDelete(!showDelete)}
        >
          <Delete fontSize={'large'} color="primary" />
        </IconButton>
      </div>
    </div>
  );
}

export default function Items(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.job.items);

  const itemsLoading = useSelector((state) => state.job.itemsLoading);
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteDevice = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('row :>> ', params);
    dispatch(deleteItem(params.id));
  };

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      headerClassName: 'table-header-logo',
      flex: 3,
    },
    {
      field: 'price',
      headerName: t('Price'),
      type: 'number',
      editable: true,
      headerClassName: 'table-header',
      //   editable: true,
      flex: 1,
    },
    {
      field: 'stock',
      headerName: t('Stock'),
      type: 'number',
      editable: true,
      headerClassName: 'table-header',
      cellClassName: 'dtype-cell',
      flex: 1,
    },
    {
      field: 'delete',
      headerName: t('Delete'),
      headerClassName: 'table-header',
      flex: 1,
      hide: !showDelete,
      renderCell: (params) => (
        <IconButton onClick={(e) => handleDeleteDevice(e, params)}>
          <Delete color="error" />
        </IconButton>
      ),
    },
  ];

  const handleCellEdit = (data) => {
    console.log('data :>> ', data);
    console.log(data.value);
    // if (data !== items) {
    const item = items.find((item) => item.id === data.id);

    if (data.value !== item[data.field]) {
      dispatch(updateItem(data.id, data.field, data.value));
    }
    // }
  };

  const rows = items.map((t, i) => t);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1, background: 'white' }}>
        <DataGrid
          loading={itemsLoading}
          rows={rows}
          columns={columns}
          onCellEditCommit={(data) => handleCellEdit(data)}
          keyboard
          disableSelectionOnClick
          headerHeight={65}
          pageSize={100}
          components={{
            Toolbar: () => CustomToolbar(props, showDelete, setShowDelete),
            LoadingOverlay: LinearProgress,
          }}
          sx={{
            minHeight: '86vh',
            padding: '15px',
            fontSize: '1.1rem',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .table-header': {
              marginTop: '10px',
            },
            '& .dtype-cell': {
              color: 'grey',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row': {
              maxHeight: '300px !important',
            },
            '& .MuiDataGrid-cell': {
              maxHeight: '300px !important',
              padding: '10px',
              fontSize: '1.4rem',
            },
            '& .MuiDataGrid-editInputCell': {
              fontSize: '1.4rem',
            },
            // "& .MuiDataGrid-columnHeaderTitle": {
            //   fontWeight: "bold",

            // },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
        />
      </div>
    </div>
  );
}
