import React from 'react';
import InvoiceSettings from '../../components/Profile/InvoiceSettings';

function Invoice({ setAdminAuthenticated }) {
  return (
    <div>
      <InvoiceSettings setAdminAuthenticated={setAdminAuthenticated} />
    </div>
  );
}

export default Invoice;
